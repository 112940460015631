import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                My Professional Journey
              </h2>

              <div className="timeline is-centered">
                <header className="timeline-header">
                  <span
                    className="tag is-medium is-primary has-text-centered"
                    style={{
                      width: "200px",
                      height: "100px",
                    }}
                  >
                    Present
                  </span>
                </header>

                <div className="timeline-item is-primary">
                  <div className="timeline-marker is-primary is-image is-32x32">
                    <img
                      src="https://media-exp2.licdn.com/dms/image/C4D0BAQGZ-5mTaPEwbQ/company-logo_100_100/0/1648043168609?e=1663804800&v=beta&t=Y1WmuepGcSIAIYvIvHrn6Vf_jRt--DNLu_avTiwgSqk"
                      alt="GeminiLogo"
                    />
                  </div>
                  <div className="timeline-content">
                    <div className="heading">March 2022</div>
                    <h1>
                      <b>Gemini: Remote - Atlanta, GA | HQ - New York, NY</b>
                    </h1>
                    <h2>
                      <em>Product Manager, Payments</em>
                    </h2>
                    <div>
                      Building Product Features technology involving Payments
                      and Crypto Exchange. We are innovating alongside with players in
                      the space such as Coinbase, Kraken, FTX, and more. We have 
                      customer-centric Products such as Credit Card, Earn, NFTs, and
                      support to trade Crypto Tokens (continuing to add tokens)
                    </div>
                  </div>
                </div>

                <div className="timeline-item is-primary">
                  <div className="timeline-marker is-primary is-image is-32x32">
                    <img
                      src="https://yt3.ggpht.com/ytc/AAUvwni1XoLZePK08HfB6xRRj7vyIb_rFSfzx95cpS_HTQ=s88-c-k-c0x00ffffff-no-rj"
                      alt="GlobalPaymentsLogo"
                    />
                  </div>
                  <div className="timeline-content">
                    <div className="heading">September 2020</div>
                    <h1>
                      <b>Global Payments: Atlanta, GA</b>
                    </h1>
                    <h2>
                      <em>Product Manager, Payments</em>
                    </h2>
                    <div>
                      Building Products (APIs and Front-End UIs) to Modernize
                      FinTech with the use of Agile and strategizing with
                      Stakeholders on Product Vision. Product features included working on
                      the Payments Platform and Authorization and Tokenization Products
                    </div>
                  </div>
                </div>

                <header className="timeline-header">
                  <span className="tag is-primary">2020</span>
                </header>

                <div className="timeline-item is-primary">
                  <div className="timeline-marker is-primary is-image is-32x32">
                    <img
                      src="https://img-cdn.tnwcdn.com/image?url=https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1216801716710125572%2Fdbtyn9Qt.jpg&signature=32339f6a3548d7c67efbb9a6d040a59e"
                      alt="BetterCloudLogo"
                    />
                  </div>
                  <div className="timeline-content">
                    <div className="heading">June 2019</div>
                    <h1>
                      <b>BetterCloud: Atlanta, GA</b>
                    </h1>
                    <h2>
                      <em>Software Engineer</em>
                    </h2>
                    <div>
                      Built NodeJS (Javascript ES6) webhooks that connected with
                      third-party APIs that seamlessly helped BetterCloud
                      customer's user management process for their SaaS
                      applications(Docusign, Cisco Meraki, Cisco DUO, Atlassian,
                      and more).
                      <br />{" "}
                      <a
                        href="https://techcrunch.com/2019/09/25/bettercloud-adds-integration-center-to-help-developers-build-and-share-integrations/ "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        TechCrunch Press Release - Includes APIs I worked on
                      </a>
                    </div>
                  </div>
                </div>

                <header className="timeline-header">
                  <span className="tag is-primary">2019</span>
                </header>
                <div className="timeline-item is-primary">
                  <div className="timeline-marker is-primary is-image is-32x32">
                    <img
                      src="https://zenprospect-production.s3.amazonaws.com/uploads/pictures/606af6d70c2a380001cb7825/picture"
                      alt="devNextLogo"
                    />
                  </div>
                  <div className="timeline-content">
                    <div className="heading">December 2018</div>
                    <h1>
                      <b>DevNext: Atlanta, GA</b>
                    </h1>
                    <h2>
                      <em>Software Engineer</em>
                    </h2>
                    <div>
                      Developed Software Applications for clients with this web agency. Technologies used included: .NET Core, SQL, C#
                    </div>
                  </div>
                </div>

                <div className="timeline-item is-primary">
                  <div className="timeline-marker is-primary is-image is-32x32">
                    <img
                      src="https://logo.clearbit.com/zuora.com"
                      alt="zuoraLogo"
                    />
                  </div>
                  <div className="timeline-content">
                    <div className="heading">June 2018</div>
                    <h1>
                      <b>Zuora: Atlanta, GA</b>
                    </h1>
                    <h2>
                      <em>Strategic Solutions Engineer Intern</em>
                    </h2>
                    <div>
                      Developed a new Java Tool that gave customers ability to
                      update subscription information (JSON) in bulk
                    </div>
                  </div>
                </div>

                <header className="timeline-header">
                  <span className="tag is-primary">2018</span>
                </header>
                <div className="timeline-item is-primary">
                  <div className="timeline-marker is-primary is-icon">
                    <i className="fas fa-flag"></i>
                  </div>
                  <div className="timeline-content">
                    <div className="heading">2017</div>
                    <h1>
                      <b>Became a Software Engineer</b>
                    </h1>
                  </div>
                </div>

                <header className="timeline-header">
                  <span className="tag is-primary">2017</span>
                </header>
                <div className="timeline-item is-primary">
                  <div className="timeline-marker is-warning is-icon">
                    <i className="fas fa-flag"></i>
                  </div>
                  <div className="timeline-content">
                    <div className="heading">2014-2017</div>
                    <h1>
                      <b>Strategic Agile Product Software Development</b>
                    </h1>
                    <h2>
                      <em>
                        Partnered with many Fortune 50 companies with their
                        Agile Software Development process as a consulting Scrum
                        Master from ideation to execution. Industries included
                        telecom, energy/utility, financial services, and more
                      </em>
                    </h2>
                  </div>
                </div>

                <header className="timeline-header">
                  <span className="tag is-primary">2014</span>
                </header>
                <div className="timeline-item is-primary">
                  <div className="timeline-marker is-warning is-image is-32x32">
                    <img
                      src="https://d2u3dcdbebyaiu.cloudfront.net/img/companyPage/logo_100129.jpg"
                      alt="kpmgLogo"
                    />
                  </div>
                  <div className="timeline-content">
                    <div className="heading">February 2014</div>
                    <h1>
                      <b>KPMG: Atlanta, GA</b>
                    </h1>
                    <h2>
                      <em>Management Consultant - Technology</em>
                    </h2>
                    <div>
                      Implemented product features and guided multi-million
                      engagements for multiple Fortune 50 companies
                    </div>
                  </div>
                </div>

                <header className="timeline-header">
                  <span className="tag is-primary">2012</span>
                </header>
                <div className="timeline-item is-primary">
                  <div className="timeline-marker is-warning is-image is-32x32">
                    <img
                      src="https://yt3.ggpht.com/ytc/AAUvwniBYUTjv5vUNz-MMfrPnhzv96CmmRqWJQmsLee70A=s900-c-k-c0x00ffffff-no-rj"
                      alt="AssurantLogo"
                    />
                  </div>
                  <div className="timeline-content">
                    <div className="heading">July 2012</div>
                    <h1>
                      <b>Assurant: Atlanta, GA</b>
                    </h1>
                    <h2>
                      <em>Project Analyst</em>
                    </h2>
                    <div>
                      Managed Six-Sigma initiatives such as improving processes
                      and automating reports
                    </div>
                  </div>
                </div>

                <div className="timeline-item is-primary">
                  <div className="timeline-marker is-warning is-image is-32x32">
                    <img
                      src="https://yt3.ggpht.com/ytc/AAUvwnicd5ffddCW97pV8K7O3zxKLY66V_NEfBm2nsAzdQ=s900-c-k-c0x00ffffff-no-rj"
                      alt="universityofGeorgiaLogo"
                    />
                  </div>
                  <div className="timeline-content">
                    <div className="heading">May 2012</div>
                    <div>
                      <b>Graduated from University of Georgia</b>
                    </div>
                    <div>
                      <div>
                        Embarking on my first professional career opportunity
                      </div>
                    </div>
                  </div>
                </div>

                <header className="timeline-header">
                  <span
                    className="tag is-medium is-primary has-text-centered"
                    style={{
                      width: "200px",
                      height: "100px",
                    }}
                  >
                    University of Georgia <br /> Terry College of Business{" "}
                    <br /> B.B.A Economics
                  </span>
                </header>
              </div>
            </div>

            <div className="section">
              <div className="abt-me-prof-pic">
                <img
                  src="https://media-exp2.licdn.com/dms/image/C5603AQFwLgVXOoX6Mg/profile-displayphoto-shrink_200_200/0/1599598620237?e=1661385600&v=beta&t=wmAfBnH6hwnomVBRLhmf2lZIBMLckCfSuXAAe3kN0bk"
                  alt="linkedinProf"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div className="timeline-content"></div>
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
